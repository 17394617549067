import React, {PureComponent} from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";
import SliderComponent from "../components/common/SliderComponent";
import ProductList from "../components/common/ProductList";
import {withRouter} from "react-router-dom";
// import ENDPOINT from "../components/common/Endpoints.json";
import axios from "axios";
import Loading from "../components/common/Loading";
import {Modal} from "react-responsive-modal";
import AddCustomToys from "../components/common/AddCustomToys";

class Search extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchKey: null,
            sliderTitle: null,
            sliderDescription: null,
            sliderImage: null,
            slugName: this.props.match.params.slugName,
            toyBrandName: '',
            productList: [],
            optionNameList: [],
            pagination: null,
            limit: '50',
            page: '1',
            isCustomToyModalOpen: true,
            isUPCSearchActive:false,
            UPCActive:false
        }
    }

    componentDidMount() {
        let searchParams = new URLSearchParams(this.props.history.location.search);
        let limit = searchParams.get('limit') === null ? '50' : searchParams.get('limit');
        let page = searchParams.get('page') === null ? '1' : searchParams.get('page');
        let UPCActive = searchParams.get('UPCActive') === 'true'
        this.setState({limit:limit})
        this.setState({page:page})
        this.setState({UPCActive:UPCActive})
        this.setState({searchKey: this.props.match.params.searchKey});
        this.getProductsFromAPI(this.props.match.params.searchKey, page !== null ? page : false, limit !== null ? limit : false );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let searchParams = new URLSearchParams(this.props.history.location.search);
        // let prevSearchParams = new URLSearchParams(prevProps.history.location.search);
        let limit = searchParams.get('limit') === null ? '50' : searchParams.get('limit');
        let page = searchParams.get('page') === null ? '1' : searchParams.get('page');

        if (prevProps.match.params.searchKey !== this.props.match.params.searchKey) {
            // console.log('Updated new Toys !!!')
            let searchKey = this.props.match.params.searchKey;
            this.getProductsFromAPI(searchKey, page, limit)
        }


        if (limit !== this.state.limit || page !== this.state.page) {
            this.setState({limit:limit})
            this.setState({page:page})
            // console.log('limit page update')
            let searchKey = this.props.match.params.searchKey;
            this.getProductsFromAPI(searchKey, page, limit)
        }
    }

    getProductsFromAPI(searchKey, page, limit) {
        this.setState({sliderTitle: null});
        this.setState({sliderDescription: null});
        this.setState({sliderImage: null});
        this.setState({productList: []});
        this.setState({searchKey: searchKey});
        let url = '';
        if (page && limit) {
            url = `${process.env.REACT_APP_SEARCH_ALL}?q=${searchKey}&page=${page}&limit=${limit}`;
        } else if (!page && limit) {
            url = `${process.env.REACT_APP_SEARCH_ALL}?q=${searchKey}&page=1&limit=${limit}`;
        } else if (page && !limit) {
            url = `${process.env.REACT_APP_SEARCH_ALL}?q=${searchKey}&page=${page}&limit=50`;
        } else {
            url = `${process.env.REACT_APP_SEARCH_ALL}?q=${searchKey}`;
        }

        setTimeout(() => {
            axios.get(url)
                .then((response) => {
                    // console.log(response)
                    this.setState({sliderTitle: response.data.banner_title});
                    this.setState({sliderDescription: response.data.banner_description});
                    this.setState({sliderImage: response.data.toy_image});
                    this.setState({toyBrandName: response.data.toy_name});
                    this.setState({productList: response.data.products});
                    this.setState({optionNameList: response.data.option_names});
                    this.setState({pagination: response.data.pagination});
                })
        }, 500)

    }

    render() {
        const {
            sliderTitle,
            searchKey,
            sliderDescription,
            sliderImage,
            productList,
            optionNameList,
            pagination,
            toyBrandName,
            isCustomToyModalOpen,
            UPCActive
        } = this.state;

        if (sliderTitle === null || sliderImage === null || sliderDescription === null) {
            return (
                <>
                    <HelmetProvider>
                        <Helmet>
                            <title>{toyBrandName + ' | Search - We R Toys'}</title>
                        </Helmet>
                    </HelmetProvider>
                    {/*<LoadingSliderComponent/>*/}

                    <Loading/>

                </>
            );
        } else {
            return (

                <>
                    <HelmetProvider>
                        <Helmet>
                            <title>{'Search | Sell Your Toys Now'}</title>
                        </Helmet>
                    </HelmetProvider>
                    <SliderComponent env={this.props.env} toyBrandName={this.props.match.params.slugName} title={sliderTitle}
                                     description={sliderDescription} image={sliderImage} from={'Home'} limitForSearch={true} />
                    <h2 className={'page-title'}>Search <strong>'{searchKey}'</strong> in all toys
                    </h2>

                    {productList.length > 0 ?
                        <ProductList env={this.props.env} quote={this.props.quote} appendQuote={(quote)=>this.props.appendQuote(quote)}  quoteBasket={this.props.quoteBasket}  changeQuoteBasketAmount={(value)=>this.props.changeQuoteBasketAmount(value)} products={productList} toyLineBrandName={this.state.toyBrandName}
                                     optionNames={optionNameList} pagination={pagination} from={'SearchAll'}/>
                        :
                        // <div className={'no-result'}>NO RESULTS MATCHED YOUR SEARCH.</div>
                        <Modal env={this.props.env} open={isCustomToyModalOpen}
                               onClose={() => {
                                   this.setState({isCustomToyModalOpen: false});
                                   this.props.history.push('/')
                               }} center>
                            <AddCustomToys env={this.props.env}
                                paramsUPCValue={UPCActive? this.props.match.params.searchKey: false}
                                modalClose={(value) => { this.setState({isCustomToyModalOpen: !value}); this.props.history.push('/') }}
                                changeQuoteBasketAmount={(value) => this.props.changeQuoteBasketAmount(value)}
                                appendQuote={(quote) => this.props.appendQuote(quote)}
                                quote={this.props.quote}/>
                        </Modal>
                    }


                </>
            );
        }

    }
}

export default withRouter(Search);
