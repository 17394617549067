import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";


class Header extends Component {
    render() {
        return (
            <>
                <header className={'container-fluid no-padding'}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-sm-4 col-md-4 col-xs-12">
                                <div className="cta">Call for a Custom Quote: <b><a href="tel:8666698697">8666698697</a></b></div>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-md-4 col-xs-12">
                                <div className="logo">
                                    <Link to={'/'}><h1>Sell Your Toys Now!</h1></Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-md-4 col-xs-12 text-end">
                                <button className={'btn btn-lg btn-primary-green submit-quote-button'} onClick={()=>{this.props.openQuoteModal()}}>Submit Quote Cart ({this.props.quoteBasket})</button>
                            </div>
                        </div>
                    </div>
                </header>

            </>
        );
    }
}

export default Header;