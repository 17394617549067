import React, {PureComponent} from 'react';
import { withRouter } from "react-router-dom";

class SelectToyLine extends PureComponent {

    constructor(props) {
        super(props);
        this.state={
            selectedToyValue: '0'
        }
    }
    componentDidMount() {
        this.setState({selectedToyValue: this.props.match.params.slugName})
    }
    componentDidUpdate() {
        this.setState({selectedToyValue: this.props.match.params.slugName})
    }

    handleSelectToyLine(value){
        this.props.history.push(`/lines/${value}/toys`);
    }
    render() {
// console.log(this.state.selectedToyValue)
        return (
            <div className={'selectToySection'}>
                <div className="form-floating">
                    <select className="form-select" id="floatingSelect"  value={this.state.selectedToyValue}
                            aria-label="select a toy name float" onChange={event => this.handleSelectToyLine(event.target.value)}>
                        <option value="">Select a toy line</option>
                        {(this.props.list || []).map((option, key)=>{
                            return (<option value={option.slug}  data-entity-id={option.id}  key={key}>{option.name}</option>)
                        })}
                    </select>
                    <label htmlFor="floatingSelect">Explore another Toy Line:</label>
                </div>
            </div>

        );
    }
}

export default withRouter(SelectToyLine);
