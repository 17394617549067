import React, {PureComponent} from 'react';
import S3 from 'react-aws-s3/dist/react-aws-s3';
import Loading from "./Loading";
import Swal from 'sweetalert2';
import S3BucketConfig from "./S3BucketConst.json";

class AddCustomToys extends PureComponent {
    constructor(props) {
        super(props);
        this.frontSideInput = React.createRef();
        this.leftSideInput = React.createRef();
        this.rightSideInput = React.createRef();
        this.backSideInput = React.createRef();
        this.state = {
            uploadedImage: [],
            loading: false,
            imageFrontSide: false,
            imageBackSide: false,
            imageLeftSide: false,
            imageRightSide: false,
            quoteNote: null,
            quantity: 1,
            productName: null,
            MPN: null,
            UPC: '',
        }
    }

    componentDidMount() {
        // console.log(this.props.quote)
        // console.log(this.props.productId)

        if (this.props.paramsUPCValue) {
            this.setState({UPC: this.props.paramsUPCValue})
        }
    }

    generateImageNewFileName(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    handleQuoteNote(value) {
        this.setState({quoteNote: value})
    }

    handleProductName(value) {
        this.setState({productName: value})
    }

    handleUPC(value) {
        if (value.includes(" ")) {
            value = value.replace(/\s/g, "");
            this.setState({UPC: value})
        } else {
            this.setState({UPC: value})
        }
    }

    handleMPN(value) {
        this.setState({MPN: value})
    }

    handleQuantity(value) {
        this.setState({quantity: value})
    }

    submitNoteAndPhotos() {
        this.uploadPhotos();
    }

    uploadPhotos() {
        let self = this;
        if (this.state.productName === "" || this.state.productName === null) {
            Swal.fire({
                title: 'Warning!',
                text: 'Product name is required!',
                icon: 'warning'
            });
            return false;
        }
        if (this.state.UPC.length < 12 && this.state.UPC.length > 14) {
            Swal.fire({
                title: 'Warning!',
                icon: 'warning',
                html: "<p>UPC Code should be minimum 12 characters long! <br /> <img src='https://cdn11.bigcommerce.com/s-cy4lua1xoh/images/stencil/original/image-manager/barcode.png?t=1674654146' width='100%' alt='UPC Code' /> <br /> <strong class='error-upc'>Please be sure to enter all 12 digits.</strong></p>"
            });
            return false;
        }
        let leftSide, rightSide, frontSide, backSide;
        frontSide = this.frontSideInput.current.files.length > 0;
        backSide = this.backSideInput.current.files.length > 0;
        leftSide = this.leftSideInput.current.files.length > 0;
        rightSide = this.rightSideInput.current.files.length > 0;
        // console.log(frontSide, backSide, leftSide, rightSide)
        if (!frontSide && !backSide && !leftSide && !rightSide) {
            Swal.fire({
                title: 'Warning!',
                text: 'Are you sure, you don\'t want to upload product images?',
                icon: 'warning',
                confirmButtonText: 'No, I\'ll upload!',
                cancelButtonText: 'Yes, sure',
                showConfirmButton: true,
                showCancelButton: true,
            }).then(value => {
                // value.isDismissed Dont want to upload image just send notes
                // value.isConfirmed I want to upload images and notes

                if (value.isDismissed) {
                    let quoteObject = [...this.props.quote];

                    // let propsQuote = [...this.props.quote];

                    quoteObject.push({
                        type: 'custom_product',
                        quantity: this.state.quantity,
                        photos: {
                            frontSideImage: null,
                            backSideImage: null,
                            leftSideImage: null,
                            rightSideImage: null,
                        },
                        product: {
                            product_name: this.state.productName,
                            upc: this.state.UPC,
                            mpn: this.state.MPN
                        },
                        note: this.state.quoteNote,
                    });

                    // console.log(quoteObject)
                    this.setState({loading: false});
                    this.props.appendQuote(quoteObject);
                    this.props.changeQuoteBasketAmount(Number(quoteObject.length));
                    Swal.fire({
                        title: 'Success!',
                        text: 'Custom Toy is successfully added!',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    }).then(value => {
                        value.isConfirmed && self.props.modalClose(true);
                    })
                    // for (let i = 0; i < propsQuote.length; i++) {
                    //     if (propsQuote[i].id === this.props.productId) {
                    //         quoteObject[i].photos.frontSideImage = null;
                    //         quoteObject[i].photos.backSideImage = null;
                    //         quoteObject[i].photos.leftSideImage = null;
                    //         quoteObject[i].photos.rightSideImage = null;
                    //         quoteObject[i].note = this.state.quoteNote;
                    //         this.setState({loading: false});
                    //         this.props.appendQuote(quoteObject);
                    //
                    //         // swal("Success", `Note and photos are successfully updated!`, "success")
                    //         Swal.fire({
                    //             title: 'Success!',
                    //             text: 'Quote Custom Note is successfully updated!',
                    //             icon: 'success',
                    //             confirmButtonText: 'Ok'
                    //         }).then(value => {
                    //             value.isConfirmed && self.props.modalClose(true);
                    //         })
                    //
                    //         break;
                    //     }
                    // }
                }
            })
        } else {
            this.handleSubmit(frontSide, backSide, leftSide, rightSide)
        }


    }

    handleSubmit(frontSide, backSide, leftSide, rightSide) {

        this.setState({loading: true});
        let FRONT_uploadedFile, FRONT_newUploadedFileName, BACK_uploadedFile, BACK_newUploadedFileName,
            LEFT_uploadedFile, LEFT_newUploadedFileName, RIGHT_uploadedFile, RIGHT_newUploadedFileName;
        if (frontSide) {
            FRONT_uploadedFile = this.frontSideInput.current.files[0];
            FRONT_newUploadedFileName = this.generateImageNewFileName(10) + '--' + FRONT_uploadedFile.name;
            console.log(`%cfrontSide image is Exist`, 'font-size:12px; background-color: #00ff00; color:#111111; padding: 2px 6px; border-radius:4px;');
        }


        if (backSide) {
            BACK_uploadedFile = this.backSideInput.current.files[0];
            BACK_newUploadedFileName = this.generateImageNewFileName(10) + '--' + BACK_uploadedFile.name;
            console.log(`%cbackSide image is Exist`, 'font-size:12px; background-color: #00ff00; color:#111111; padding: 2px 6px; border-radius:4px;');
        }


        if (leftSide) {
            LEFT_uploadedFile = this.leftSideInput.current.files[0];
            LEFT_newUploadedFileName = this.generateImageNewFileName(10) + '--' + LEFT_uploadedFile.name;
            console.log(`%cleftSide image is Exist`, 'font-size:12px; background-color: #00ff00; color:#111111; padding: 2px 6px; border-radius:4px;');
        }


        if (rightSide) {
            RIGHT_uploadedFile = this.rightSideInput.current.files[0];
            RIGHT_newUploadedFileName = this.generateImageNewFileName(10) + '--' + RIGHT_uploadedFile.name;
            console.log(`%crightSide image is Exist`, 'font-size:12px; background-color: #00ff00; color:#111111; padding: 2px 6px; border-radius:4px;');
        }
        let self = this;

        Promise.all([
            frontSide && this.uploadS3Bucket(FRONT_uploadedFile, FRONT_newUploadedFileName, 'front'),
            backSide && this.uploadS3Bucket(BACK_uploadedFile, BACK_newUploadedFileName, 'back'),
            leftSide && this.uploadS3Bucket(LEFT_uploadedFile, LEFT_newUploadedFileName, 'left'),
            rightSide && this.uploadS3Bucket(RIGHT_uploadedFile, RIGHT_newUploadedFileName, 'right')
        ]).then((responses) => {
            let frontResponse = responses[0]
            let backResponse = responses[1]
            let leftResponse = responses[2]
            let rightResponse = responses[3]

            if (frontResponse) {
                this.setState({imageFrontSide: frontResponse.location})
            }
            if (backResponse) {
                this.setState({imageBackSide: backResponse.location})
            }
            if (leftResponse) {
                this.setState({imageLeftSide: leftResponse.location})
            }
            if (rightResponse) {
                this.setState({imageRightSide: rightResponse.location})
            }


            let quoteObject = [...this.props.quote];
            quoteObject.push({
                type: 'custom_product',
                product: {
                    product_name: this.state.productName,
                    upc: this.state.UPC,
                    mpn: this.state.MPN
                },
                quantity: this.state.quantity,
                photos: {
                    frontSideImage: frontResponse ? frontResponse.location : null,
                    backSideImage: backResponse ? backResponse.location : null,
                    leftSideImage: leftResponse ? leftResponse.location : null,
                    rightSideImage: rightResponse ? rightResponse.location : null
                },
                note: this.state.quoteNote,

            });

            // console.log(quoteObject)
            this.setState({loading: false});
            this.props.appendQuote(quoteObject);
            this.props.changeQuoteBasketAmount(Number(quoteObject.length));
            Swal.fire({
                title: 'Success!',
                text: 'Custom Toy is successfully added!',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(value => {
                value.isConfirmed && self.props.modalClose(true);
            })
            // let propsQuote = [...this.props.quote];
            // for (let i = 0; i < propsQuote.length; i++) {
            //     if (propsQuote[i].id === this.props.productId) {
            //         quoteObject[i].photos.frontSideImage = frontResponse ? frontResponse.location : null;
            //         quoteObject[i].photos.backSideImage = backResponse ? backResponse.location : null;
            //         quoteObject[i].photos.leftSideImage = leftResponse ? leftResponse.location : null;
            //         quoteObject[i].photos.rightSideImage = rightResponse ? rightResponse.location : null;
            //         quoteObject[i].note = this.state.quoteNote;
            //         this.setState({loading: false});
            //         this.props.appendQuote(quoteObject);
            //
            //         Swal.fire({
            //             title: 'Success!',
            //             text: 'Note and photos are successfully updated!',
            //             icon: 'success',
            //             confirmButtonText: 'Ok'
            //         }).then(value => {
            //             value.isConfirmed && self.props.modalClose(true);
            //         })
            //
            //         break;
            //     }
            // }
        })


    }


    async uploadS3Bucket(uploadedFile, newUploadedFileName, side) {

        const ReactS3Client = new S3(S3BucketConfig);
        return ReactS3Client.uploadFile(uploadedFile, newUploadedFileName)

    }

    render() {
        const {loading, imageFrontSide, imageBackSide, imageLeftSide, imageRightSide, UPC} = this.state;
        return (
            <>
                {loading && <Loading/>}
                <div className="container add-details-modal">
                    <h2>{UPC !== '' ? 'Add a new product to the database' : 'Add a new custom toy'}</h2>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="productName"
                                               onChange={(e) => this.handleProductName(e.target.value)}/>
                                        <label htmlFor="productName">Product Name</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="UPC" maxLength={12}
                                               value={UPC}
                                               onChange={(e) => this.handleUPC(e.target.value)}/>
                                        <label htmlFor="UPC">UPC <small>(Optional)</small></label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="MPN"
                                               onChange={(e) => this.handleMPN(e.target.value)}/>
                                        <label htmlFor="MPN">MPN <small>(Optional)</small></label>
                                    </div>
                                    <div className="form-floating mb-3 col-md-3">
                                        <input type="number" className="form-control" id="quantity" max={999} min={1}
                                               defaultValue={"1"}
                                               onChange={(e) => this.handleQuantity(e.target.value)}/>
                                        <label htmlFor="quantity">Quantity</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                    <textarea className="form-control" placeholder="Leave a note here" id="quote-note"
                                              onChange={(e) => this.handleQuoteNote(e.target.value)}/>
                                        <label htmlFor="quote-note">Note</label>
                                    </div>
                                </div>
                            </div>


                            <div className="row mt-2 mb-2 imageUploadSection">
                                <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                                    <h3>Front Image</h3>
                                    <div className="input-group">
                                        <input type="file" className="form-control" id={'frontSideInput'}
                                               multiple={false}
                                               ref={this.frontSideInput}
                                               aria-describedby="frontSideInput" aria-label="Upload"/>
                                    </div>


                                    <h3 className={'mt-2'}>Back Image</h3>
                                    <div className="input-group">
                                        <input type="file" className="form-control" id={'backSideInput'}
                                               multiple={false}
                                               ref={this.backSideInput}
                                               aria-describedby="backSideInput" aria-label="Upload"/>

                                    </div>

                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                                    <h3>Left Side Image</h3>
                                    <div className="input-group">
                                        <input type="file" className="form-control" id={'leftSideInput'}
                                               multiple={false}
                                               ref={this.leftSideInput}
                                               aria-describedby="leftSideInput" aria-label="Upload"/>
                                    </div>

                                    <h3 className={'mt-2'}>Right Side Image</h3>
                                    <div className="input-group">
                                        <input type="file" className="form-control" id={'rightSideInput'}
                                               multiple={false}
                                               ref={this.rightSideInput}
                                               aria-describedby="rightSideInput" aria-label="Upload"/>
                                    </div>

                                </div>

                            </div>
                            <div className="row mt-2">

                                <div className="col-lg-6 col-md-6">
                                    <div className="imagePreviews">
                                        {imageFrontSide && <img src={imageFrontSide} alt="imageFrontSide"/>}
                                        {imageBackSide && <img src={imageBackSide} alt="imageBackSide"/>}
                                        {imageLeftSide && <img src={imageLeftSide} alt="imageLeftSide"/>}
                                        {imageRightSide && <img src={imageRightSide} alt="imageRightSide"/>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 text-end">
                                    <button className={'btn btn-primary'}
                                            onClick={() => this.submitNoteAndPhotos()}>Add to Quote Cart
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default AddCustomToys;
