import React, { PureComponent} from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";
import axios from 'axios';
import SliderComponent from "../components/common/SliderComponent";
import ProductList from "../components/common/ProductList";
import {withRouter} from "react-router-dom";
// import ENDPOINT from '../components/common/Endpoints.json';
import logo from '../logo.png';

class Home extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            toyLines: [],
            products: [],
            mainSlider: '',
            upcImage:null,
            mainTitle: '',
            mainDescription: '',
            productList: [],
            optionNameList:[],
            pagination: null,
            limit:'50',
            page:'1',
            isUPCSearchActive:false
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        let searchParams = new URLSearchParams(this.props.history.location.search);
        let limit = searchParams.get('limit') === null ? '50' : searchParams.get('limit');
        let page = searchParams.get('page') === null ? '1' : searchParams.get('page');


        if (limit !== this.state.limit || page !== this.state.page) {
            this.setState({limit:limit})
            this.setState({page:page})
            this.getBrandFromAPI(limit, page)
        }
    }

    getBrandFromAPI(limit, page){
        let url = '';
        url = `${process.env.REACT_APP_GET_DATA}?page=${page}&limit=${limit}`;

        axios.get(url)
            .then((response) => {
                // console.log(response)
                this.setState({toyLines: response.data.toy_lines})
                this.setState({mainSlider: response.data.toy_lines[0].toy_image})
                // this.setState({mainTitle: response.data.toy_lines[0].banner_title})
                this.setState({mainTitle: <>TURN YOUR TOY COLLECTION <br />INTO CASH</>})
                this.setState({mainDescription: response.data.toy_lines[0].banner_description})
                this.setState({productList: response.data.products});
                this.setState({optionNameList: response.data.option_names});
                this.setState({pagination: response.data.pagination});
                this.setState({upcImage: response.data.toy_lines[0].upc_image});
            })
    }


    componentDidMount() {

        let searchParams = new URLSearchParams(this.props.history.location.search);
        let limit = searchParams.get('limit') === null ? '50' : searchParams.get('limit');
        let page = searchParams.get('page') === null ? '1' : searchParams.get('page');
        this.setState({limit:limit})
        this.setState({page:page})
        // console.log('Did Mount Toys.')
        this.getBrandFromAPI(limit, page)

    }

    render() {
        const { mainTitle, mainDescription, productList,optionNameList,pagination,upcImage} = this.state;
        return (
            <>
                <HelmetProvider>
                    <Helmet>
                        <title>{'Home | Sell Your Toys Now'}</title>
                    </Helmet>
                </HelmetProvider>

                <SliderComponent   env={this.props.env} upcImage={upcImage} image={logo} title={mainTitle} description={mainDescription} from={'Home'} isUPCSearchActive={(value)=>{this.setState({isUPCSearchActive:value})}}/>
                {/*<Toolbox />*/}
                {productList.length>0 &&
                    <ProductList   quote={this.props.quote} appendQuote={(quote)=>this.props.appendQuote(quote)}  quoteBasket={this.props.quoteBasket}  changeQuoteBasketAmount={(value)=>this.props.changeQuoteBasketAmount(value)} products={productList} toyLineBrandName={this.state.toyBrandName}
                                 optionNames={optionNameList} pagination={pagination} from={'Home'}/>

                }
            </>
        );
    }
}

export default withRouter(Home);
