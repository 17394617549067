import React, {PureComponent} from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";
import SliderComponent from "../components/common/SliderComponent";
import ProductList from "../components/common/ProductList";
import {withRouter} from "react-router-dom";
// import ENDPOINT from "../components/common/Endpoints.json";
import axios from "axios";
import Loading from "../components/common/Loading";
import AddCustomToys from "../components/common/AddCustomToys";
import {Modal} from "react-responsive-modal";

class Search extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchKey : null,
            sliderTitle: null,
            sliderDescription: null,
            sliderImage: null,
            slugName: this.props.match.params.slugName,
            toyBrandName: '',
            upcImage:null,
            productList: [],
            optionNameList: [],
            pagination: null,
            limit:'50',
            page:'1',
            isCustomToyModalOpen: true,
        }
    }
    componentDidMount() {
        let searchParams = new URLSearchParams(this.props.history.location.search);
        let limit = searchParams.get('limit') === null ? '50' : searchParams.get('limit');
        let page = searchParams.get('page') === null ? '1' : searchParams.get('page');
        this.setState({limit:limit})
        this.setState({page:page})
        this.setState({searchKey: this.props.match.params.searchKey});
        this.getProductsFromAPI(this.props.match.params.searchKey, page !== null ? page : false, limit !== null ? limit : false );
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let searchParams = new URLSearchParams(this.props.history.location.search);
        // let prevSearchParams = new URLSearchParams(prevProps.history.location.search);
        let limit = searchParams.get('limit') === null ? '50' : searchParams.get('limit');
        let page = searchParams.get('page') === null ? '1' : searchParams.get('page');

        if (prevProps.match.params.searchKey !== this.props.match.params.searchKey) {
            // console.log('Updated new Toys !!!')
            let searchKey = this.props.match.params.searchKey;
            this.setState({searchKey: this.props.match.params.searchKey});
            this.getProductsFromAPI(searchKey, page, limit)
        }


        if (limit !== this.state.limit || page !== this.state.page) {
            this.setState({limit:limit})
            this.setState({page:page})
            this.setState({searchKey: this.props.match.params.searchKey});
            // console.log('limit page update')
            let searchKey = this.props.match.params.searchKey;
            this.getProductsFromAPI(searchKey, page, limit)
        }
    }
    getProductsFromAPI(searchKey, page, limit) {
        this.setState({sliderTitle: null});
        this.setState({sliderDescription: null});
        this.setState({sliderImage: null});
        this.setState({productList: []});
        let url,urlForSLider = '';
        if (page && limit) {
            url = `${process.env.REACT_APP_SEARCH + this.props.match.params.slugName}?q=${searchKey}&page=${page}&limit=${limit}`;
        } else if (!page && limit) {
            url = `${process.env.REACT_APP_SEARCH + this.props.match.params.slugName}?q=${searchKey}&page=1&limit=${limit}`;
        } else if (page && !limit) {
            url = `${process.env.REACT_APP_SEARCH + this.props.match.params.slugName}?q=${searchKey}&page=${page}&limit=50`;
        } else {
            url = `${process.env.REACT_APP_SEARCH + this.props.match.params.slugName}?q=${searchKey}`;
        }
        urlForSLider = `${process.env.REACT_APP_GET_DATA + this.props.match.params.slugName}`;
        // url = `${ENDPOINT.search + this.props.match.params.slugName}?q=${searchKey}`
        setTimeout(() => {
            axios.get(urlForSLider)
                .then((response) => {
                     // console.log(response)
                    this.setState({sliderTitle: response.data.banner_title});
                    this.setState({sliderDescription: response.data.banner_description});
                    this.setState({sliderImage: response.data.toy_image});
                    this.setState({toyBrandName: response.data.toy_name});
                    this.setState({upcImage: response.data.upc_image});
                })
        }, 500)

        setTimeout(() => {
            axios.get(url)
                .then((response) => {
                    // console.log(response)

                    // this.setState({toyBrandName: response.data.toy_name});
                    this.setState({productList: response.data.products});
                    this.setState({optionNameList: response.data.option_names});
                    this.setState({pagination: response.data.pagination});
                })
        }, 900)

    }
    render() {
        const {sliderTitle, searchKey, sliderDescription, sliderImage, productList, optionNameList, pagination,isCustomToyModalOpen,upcImage} = this.state;

        if (sliderTitle === null || sliderImage === null || sliderDescription === null) {
            return (
                <>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.state.toyBrandName + ' | Buy List - We R Toys'}</title>
                        </Helmet>
                    </HelmetProvider>
                    {/*<LoadingSliderComponent/>*/}

                    <Loading/>

                </>
            );
        } else {
            return (

                <>
                    <HelmetProvider>
                        <Helmet>
                            <title>{'Search | Sell Your Toys Now'}</title>
                        </Helmet>
                    </HelmetProvider>
                    <SliderComponent env={this.props.env} upcImage={upcImage} toyBrandName={this.state.toyBrandName} title={sliderTitle}
                                     description={sliderDescription} image={sliderImage} from={'ToyLineSearch'}/>
                    <h2 className={'page-title'}>Search <strong>'{searchKey}'</strong> in {this.props.match.params.slugName} toys</h2>
                    {productList.length > 0 ?
                        <ProductList env={this.props.env} quote={this.props.quote} appendQuote={(quote)=>this.props.appendQuote(quote)}  quoteBasket={this.props.quoteBasket}  changeQuoteBasketAmount={(value)=>this.props.changeQuoteBasketAmount(value)} products={productList} toyLineBrandName={this.state.toyBrandName}
                                     optionNames={optionNameList} pagination={pagination} from={'Search'}/>
                        :
                        // <div className={'no-result'}>NO RESULTS MATCHED YOUR SEARCH.</div>
                        <Modal env={this.props.env} open={isCustomToyModalOpen} onClose={() => {
                            this.setState({isCustomToyModalOpen: false})
                            this.props.history.push(`/lines/${this.props.match.params.slugName}/toys`)
                        }} center>

                            <AddCustomToys env={this.props.env}
                                modalClose={(value) => { this.setState({isCustomToyModalOpen: !value}); this.props.history.push(`/lines/${this.props.match.params.slugName}/toys`) }}
                                changeQuoteBasketAmount={(value) => this.props.changeQuoteBasketAmount(value)}
                                appendQuote={(quote) => this.props.appendQuote(quote)}
                                quote={this.props.quote}/>
                        </Modal>
                    }


                </>
            );
        }

    }
}

export default withRouter(Search);
