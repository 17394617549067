import React, {PureComponent} from 'react';
import {withRouter} from "react-router-dom";
import SliderComponent from "../components/common/SliderComponent";
import axios from "axios";
// import LoadingSliderComponent from "../components/common/LoadingSliderComponent";
import {Helmet, HelmetProvider} from "react-helmet-async";
import ProductList from "../components/common/ProductList";
import Loading from "../components/common/Loading";
// import ENDPOINT from '../components/common/Endpoints.json';

class Toys extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            sliderTitle: null,
            sliderDescription: null,
            sliderImage: null,
            upcImage:null,
            slugName: this.props.match.params.slugName,
            toyBrandName: '',
            productList: [],
            optionNameList: [],
            pagination: null,
            limit:'50',
            page:'1'
        }
    }

    componentDidMount() {
        let searchParams = new URLSearchParams(this.props.history.location.search);
        let limit = searchParams.get('limit') === null ? '50' : searchParams.get('limit');
        let page = searchParams.get('page') === null ? '1' : searchParams.get('page');
        this.setState({limit:limit})
        this.setState({page:page})
        // console.log('Did Mount Toys.')
        let slugName = this.props.match.params.slugName;
        // let subLine = this.props.match.params.subLine !== undefined ? this.props.match.params.subLine : null;
        this.getBrandFromAPI(slugName, limit !== null ? limit : false, page !== null ? page : false)
    }

    getBrandFromAPI(slugName, limit, page) {
        this.setState({sliderTitle: null});
        this.setState({sliderDescription: null});
        this.setState({sliderImage: null});
        this.setState({productList: []});
        let url = '';
        if (page && limit) {
            url = `${process.env.REACT_APP_GET_DATA + slugName}?page=${page}&limit=${limit}`;
        } else if (!page && limit) {
            url = `${process.env.REACT_APP_GET_DATA + slugName}?page=1&limit=${limit}`;
        } else if (page && !limit) {
            url = `${process.env.REACT_APP_GET_DATA + slugName}?page=${page}&limit=50`;
        } else {
            url = `${process.env.REACT_APP_GET_DATA + slugName}`;
        }
        setTimeout(() => {
            axios.get(url)
                .then((response) => {
                    // console.log(response)
                    this.setState({sliderTitle: response.data.banner_title});
                    this.setState({sliderDescription: response.data.banner_description});
                    this.setState({sliderImage: response.data.toy_image});
                    this.setState({toyBrandName: response.data.toy_name});
                    this.setState({productList: response.data.products});
                    this.setState({optionNameList: response.data.option_names});
                    this.setState({pagination: response.data.pagination});
                    this.setState({upcImage: response.data.upc_image});
                })
        }, 500)

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let searchParams = new URLSearchParams(this.props.history.location.search);
        let limit = searchParams.get('limit') === null ? '50' : searchParams.get('limit');
        let page = searchParams.get('page') === null ? '1' : searchParams.get('page');

        if (prevProps.match.params.slugName !== this.props.match.params.slugName) {
            // console.log('Updated new Toys !!!')
            let slugName = this.props.match.params.slugName;
            this.getBrandFromAPI(slugName, limit, page)
        }

        if (limit !== this.state.limit || page !== this.state.page) {
            this.setState({limit:limit})
            this.setState({page:page})
            // console.log('limit page update')
            let slugName = this.props.match.params.slugName;
            this.getBrandFromAPI(slugName, limit, page)
        }
    }


    render() {

        const {sliderTitle, sliderDescription, sliderImage, productList, optionNameList, pagination,upcImage} = this.state;


        if (sliderTitle === null || sliderImage === null || sliderDescription === null) {
            return (
                <>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.state.toyBrandName + ' | Buy List - Sell Your Toys Now'}</title>
                        </Helmet>
                    </HelmetProvider>
                    {/*<LoadingSliderComponent/>*/}

                    <Loading/>

                </>
            );
        } else {
            return (
                <>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.state.toyBrandName + ' | Buy List - Sell Your Toys Now'}</title>
                        </Helmet>
                    </HelmetProvider>
                    <SliderComponent env={this.props.env} upcImage={upcImage} toyBrandName={this.state.toyBrandName} title={sliderTitle}
                                     description={sliderDescription} image={sliderImage}/>

                    {productList.length > 0 ?
                        <ProductList env={this.props.env} quote={this.props.quote} appendQuote={(quote)=>this.props.appendQuote(quote)}  quoteBasket={this.props.quoteBasket}  changeQuoteBasketAmount={(value)=>this.props.changeQuoteBasketAmount(value)} products={productList} toyLineBrandName={this.state.toyBrandName}
                                     optionNames={optionNameList} pagination={pagination}/>
                        :
                        <div className={'no-result'}>NO RESULTS MATCHED YOUR SEARCH.</div>
                    }


                </>
            );
        }


    }
}

export default withRouter(Toys);
