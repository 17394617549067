import React, {PureComponent} from 'react';
import axios from "axios";
// import ENDPOINT from "./Endpoints.json";
import SelectToyLine from "./SelectToyLine";
import {withRouter} from "react-router-dom";
import SearchIcon from './search.svg';
import Swal from "sweetalert2";
require('dotenv');
class SliderComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            toyLineList: [],
            searchKey: '',
            searchByName: false,
            searchByUPC: true,
            UPCSearchIsValid:false
        }
    }

    componentDidMount() {
// console.log(process.env)
        let toyLineList = [];
        axios.get(process.env.REACT_APP_GET_DATA)
            .then((response) => {
                // console.log(response)
                response.data.toy_lines.map((line) => {
                    return toyLineList.push({entityId: line.id, name: line.toy_name, slug: line.slug})
                })
            }).then(() => {
            this.setState({toyLineList: toyLineList});
        })
    }

    handleSelectLimitSize(value) {
        // console.log(this.props.from)
        let searchParams = new URLSearchParams(this.props.history.location.search);


        if (this.props.limitForSearch) {

            let page = searchParams.get('page');
            if (page !== null) {
                this.props.history.push(`/search-all/${this.props.match.params.searchKey}?page=1&limit=${value}`);
            } else {
                this.props.history.push(`/search-all/${this.props.match.params.searchKey}?limit=${value}`);
            }
        }
        if (this.props.from === 'Home') {
            if (this.props.limitForSearch) {
                let page = searchParams.get('page');
                if (page !== null) {
                    this.props.history.push(`/search-all/${this.props.match.params.searchKey}?page=1&limit=${value}`);
                } else {
                    this.props.history.push(`/search-all/${this.props.match.params.searchKey}?limit=${value}`);
                }
            } else {
                let page = searchParams.get('page');
                if (page !== null) {
                    this.props.history.push(`/?page=1&limit=${value}`);
                } else {
                    this.props.history.push(`/?limit=${value}`);
                }
            }


        } else if (this.props.from === 'ToyLineSearch') {

            let page = searchParams.get('page');
            if (page !== null) {
                this.props.history.push(`/search/${this.props.match.params.slugName}?page=1&limit=${value}`);
            } else {
                this.props.history.push(`/search/${this.props.match.params.slugName}?limit=${value}`);
            }
        } else {
            let page = searchParams.get('page');
            if (page !== null) {
                this.props.history.push(`/lines/${this.props.match.params.slugName}/toys?page=1&limit=${value}`);
            } else {
                this.props.history.push(`/lines/${this.props.match.params.slugName}/toys?limit=${value}`);
            }
        }

    }

    handleSearchToy(searchKey) {
        const {searchByUPC, searchByName} = this.state;

            if (searchByUPC) {
                if (searchKey.includes(" ")) {
                    searchKey = searchKey.replace(/\s/g, "");
                    if (searchKey.match(/\s/g)!==null && searchKey.match(/^[0-9]+$/)===null){
                        this.handleSearchBy('name');
                        this.setState({searchKey: searchKey});
                    }else {
                        if (searchKey.length >11 && searchKey.length<15) {
                            this.setState({UPCSearchIsValid: true})


                        } else {
                            this.setState({UPCSearchIsValid: false})

                        }
                        this.setState({searchKey: searchKey});
                    }

                } else {
                    if (searchKey.match(/^[0-9]+$/)===null){
                        this.handleSearchBy('name');
                        this.setState({searchKey: searchKey});
                    }else {
                        if (searchKey.length >11 && searchKey.length<15) {
                            this.setState({UPCSearchIsValid: true})
                        } else {
                            this.setState({UPCSearchIsValid: false})

                        }
                        this.setState({searchKey: searchKey});
                    }

                }
            } else if (searchByName) {
                this.setState({searchKey: searchKey});
            }

    }


    submitSearchToy(key) {
        const {searchByUPC, UPCSearchIsValid} = this.state;
        const isButton = key.type === "click" || key.type === "touchstart";
        if (this.state.searchKey) {
            if (this.props.from === 'Home') {
                if (key.keyCode === 13 || isButton) {
                    // console.log(this.state.searchKey)
                    if (searchByUPC) {
                        if (UPCSearchIsValid) {
                            this.props.history.push(`/search-all/${this.state.searchKey}?UPCActive=true`);
                        } else {
                            Swal.fire({
                                title: 'Warning!',
                                icon: 'warning',
                                html: "<p>UPC Code should be minimum 12 characters long! <br /> <img src='https://cdn11.bigcommerce.com/s-cy4lua1xoh/images/stencil/original/image-manager/barcode.png?t=1674654146' width='100%' alt='UPC Code' /> <br /> <strong class='error-upc'>Please be sure to enter all 12 digits.</strong></p>"                            });
                        }
                    } else {
                        this.props.history.push(`/search-all/${this.state.searchKey}`);
                    }

                }
            } else {
                if (key.keyCode === 13 || isButton) {
                    // console.log(this.state.searchKey)
                    if (searchByUPC) {
                        if (UPCSearchIsValid) {
                            this.props.history.push(`/search/${this.props.match.params.slugName}/${this.state.searchKey}?UPCActive=true`);
                        } else {
                            Swal.fire({
                                title: 'Warning!',
                                icon: 'warning',
                                html: "<p>UPC Code should be minimum 12 characters long! <br /> <img src='https://cdn11.bigcommerce.com/s-cy4lua1xoh/images/stencil/original/image-manager/barcode.png?t=1674654146' width='100%' alt='UPC Code' /> <br /> <strong class='error-upc'>Please be sure to enter all 12 digits.</strong></p>"
                            });
                        }
                    } else {
                        this.props.history.push(`/search/${this.props.match.params.slugName}/${this.state.searchKey}`);
                    }

                }
            }
        }
    }

    handleSearchBy(value) {
        const {searchKey} = this.state;
        if (value === 'name') {
            this.setState({searchByName: true});
            this.setState({searchByUPC: false});
        } else {

            this.setState({searchByName: false});
            this.setState({searchByUPC: true});
            if (searchKey.includes(" ")) {
                let searchKeyUpdated = searchKey.replace(/\s/g, "");
                // console.log(searchKeyUpdated.length)
                this.setState({searchKey: searchKeyUpdated});
                // console.log(searchKeyUpdated.length)
                if (searchKeyUpdated.length !== 12) {
                    this.setState({UPCSearchIsValid: false})
                } else {
                    this.setState({UPCSearchIsValid: true})
                }


            } else {
                this.setState({searchKey: searchKey});
                // console.log(searchKey.length)
                if (searchKey.length !== 12) {
                    this.setState({UPCSearchIsValid: false})
                } else {
                    this.setState({UPCSearchIsValid: true})
                }

            }
        }
    }

    render() {
        const {searchByName, searchByUPC, searchKey} = this.state;
        let searchParams = new URLSearchParams(this.props.history.location.search);
        let limit = searchParams.get('limit') === null ? '50' : searchParams.get('limit');
        return (
            <>
                <section className="SliderComponent container-fluid">

                    <div className="container">
                        <div className="row showOnMobileBrandCover" style={{
                            display: this.props.image ? 'flex' : 'none'
                        }}>
                            <img src={this.props.image} className={"banner-image"} alt={this.props.title}
                                 width={"50%"}/>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 introduction">
                                <h1>{this.props.title}</h1>
                                <p>
                                    {this.props.upcImage &&
                                        <img src={this.props.upcImage} className={'upcImage'} alt="UPC Number"
                                             width={"50%"}/>
                                    }
                                    {this.props.description}
                                </p>
                                <div className="form-floating">
                                    <input type="search"
                                           className="form-control"
                                           value={searchKey}
                                           id="search-input"
                                           placeholder="Search"
                                           minLength={searchByUPC ? 12 : 3}
                                           maxLength={searchByUPC ? 14 : 100}
                                           onChange={(e) => this.handleSearchToy(e.target.value)}
                                           onKeyUp={(e) => this.submitSearchToy(e)}
                                    />
                                    <button
                                        className={"btn btn-sm btn-outline search-button"}
                                        onClick={e => this.submitSearchToy(e)}
                                        onTouchStart={e => this.submitSearchToy(e)}

                                    >
                                        <img src={SearchIcon} alt={"search icon"} width={36}/>
                                    </button>
                                    <label htmlFor="floatingInput">Find
                                        your <b>{this.props.toyBrandName}</b> toys (enter UPC or name of toy)</label>

                                </div>
                                <div className={'search-by'}>
                                    <label><strong>Choose your search option:</strong></label>
                                    <label htmlFor="search-by-name"><input type="radio" id={'search-by-name'}
                                                                           checked={searchByName}
                                                                           name={'search-by'}
                                                                           readOnly={true}
                                                                           onClick={(e) => this.handleSearchBy(e.target.value)}
                                                                           value={'name'}/> Search by name</label>
                                    <label htmlFor="search-by-upc"><input type="radio" id={'search-by-upc'}
                                                                          checked={searchByUPC}
                                                                          name={'search-by'}
                                                                          readOnly={true}
                                                                          onClick={(e) => this.handleSearchBy(e.target.value)}
                                                                          value={'upc'}/> Search by UPC</label>
                                </div>

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 banner"
                                 style={{
                                     backgroundImage: `url(${this.props.image})`,
                                     backgroundRepeat: 'no-repeat',
                                     backgroundSize: this.props.from === 'Home' ? '260px' : '300px',
                                     backgroundPosition: 'center right'
                                 }}>
                                <img src={this.props.upcImage} className={'upcImage'} alt={"UPC"}/>

                                <div className="row page-toy-line-section">
                                    <div className="col-lg-3 col-xl-3 col-md-3 col-xs-3 page-size">
                                        <div className="form-floating">
                                            <select className="form-select" id="pageSize" value={limit}
                                                    aria-label="Floating label select example"
                                                    onChange={event => this.handleSelectLimitSize(event.target.value)}>
                                                <option value={"25"}>25</option>
                                                <option value={"50"}>50</option>
                                                <option value={"75"}>75</option>
                                                <option value={"100"}>100</option>
                                                <option value={"150"}>150</option>
                                                <option value={"200"}>200</option>
                                            </select>
                                            <label htmlFor="pageSize">Items</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-xl-8 col-md-8 col-xs-8 toy-line">
                                        <div className="form-floating">
                                            <SelectToyLine list={this.state.toyLineList}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </section>

            </>
        );
    }
}

export default withRouter(SliderComponent);
