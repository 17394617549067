import React, {PureComponent} from 'react';
import S3 from 'react-aws-s3/dist/react-aws-s3';
import Loading from "./Loading";
import Swal from 'sweetalert2';
import S3BucketConfig from "./S3BucketConst.json";
class UpdateNoteAndPhoto extends PureComponent {
    constructor(props) {
        super(props);
        this.frontSideInput = React.createRef();
        this.leftSideInput = React.createRef();
        this.rightSideInput = React.createRef();
        this.backSideInput = React.createRef();
        this.state = {
            uploadedImage: [],
            loading: false,
            imageFrontSide: false,
            imageBackSide: false,
            imageLeftSide: false,
            imageRightSide: false,
            quoteNote: null,
            currentProductQuote: [],
            isLoaded : false,
            quantity: null
        }
    }

    componentDidMount() {

        if (this.props.quote.length > 0) {
            let currentProductQuote = (this.props.quote || []).filter(quote => {
                return quote.id === this.props.productId
            });
            console.log(currentProductQuote)

            this.setState({currentProductQuote:currentProductQuote})
            this.setState({isLoaded:true})
            this.setState({quantity:currentProductQuote[0].quantity})
            // console.log(this.props.productId)
        }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.productId !==this.props.productId) {
            let currentProductQuote = (this.props.quote || []).filter(quote => {
                return quote.id === this.props.productId
            });
            console.log(currentProductQuote)
            this.setState({currentProductQuote:currentProductQuote})
            this.setState({isLoaded:true})
            this.setState({quantity:currentProductQuote[0].quantity})
        }

        // console.log(this.props.productId)
    }

    generateImageNewFileName(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    handleUpdateQuoteNote(value) {
        this.setState({quoteNote: value})
    }
    handleUpdateQuantity(value) {
        this.setState({quantity: value})
    }

    submitNoteAndPhotos() {
        this.uploadPhotos();
    }

    uploadPhotos() {
        let self = this;
        let leftSide, rightSide, frontSide, backSide;
        const {currentProductQuote} = this.state;
        frontSide = this.frontSideInput.current.files.length > 0;
        backSide = this.backSideInput.current.files.length > 0;
        leftSide = this.leftSideInput.current.files.length > 0;
        rightSide = this.rightSideInput.current.files.length > 0;
        // console.log(frontSide, backSide, leftSide, rightSide)
        if (this.state.quantity!=='') {
            if (!frontSide && !backSide && !leftSide && !rightSide) {
                Swal.fire({
                    title: 'Warning!',
                    text: 'Are you sure, you don\'t want to upload product images?',
                    icon: 'warning',
                    confirmButtonText: 'No, I\'ll upload!',
                    cancelButtonText: 'Yes, sure',
                    showConfirmButton: true,
                    showCancelButton: true,
                }).then(value => {
                    // value.isDismissed Dont want to upload image just send notes
                    // value.isConfirmed I want to upload images and notes

                    if (value.isDismissed) {
                        let quoteObject = [...this.props.quote];

                        let propsQuote = [...this.props.quote];
                        for (let i = 0; i < propsQuote.length; i++) {
                            if (propsQuote[i].id === this.props.productId) {
                                quoteObject[i].photos.frontSideImage = currentProductQuote[0].photos.frontSideImage !== null ? currentProductQuote[0].photos.frontSideImage : null;
                                quoteObject[i].photos.backSideImage = currentProductQuote[0].photos.backSideImage !== null ? currentProductQuote[0].photos.backSideImage : null;
                                quoteObject[i].photos.leftSideImage = currentProductQuote[0].photos.leftSideImage !== null ? currentProductQuote[0].photos.leftSideImage : null;
                                quoteObject[i].photos.rightSideImage = currentProductQuote[0].photos.rightSideImage !== null ? currentProductQuote[0].photos.rightSideImage : null;
                                quoteObject[i].note = this.state.quoteNote;
                                quoteObject[i].quantity = this.state.quantity;
                                this.setState({loading: false});
                                this.props.appendQuote(quoteObject);

                                // swal("Success", `Note and photos are successfully updated!`, "success")
                                Swal.fire({
                                    title: 'Success!',
                                    text: 'Quote Custom Note is successfully updated!',
                                    icon: 'success',
                                    confirmButtonText: 'Ok'
                                }).then(value => {
                                    document.getElementById('detail-button-id-' + this.props.productId).classList.remove('show');
                                    this.props.changeInitialProductQuantity(this.state.quantity,this.props.productId)
                                    if (this.props.fromCart && this.props.quoteBasket===0) {
                                        this.props.onCloseCartModal()
                                    }
                                    value.isConfirmed && self.props.modalClose(true);
                                })

                                break;
                            }
                        }
                    }
                })
            } else {
                this.handleSubmit(frontSide, backSide, leftSide, rightSide)
            }
        }else {
            Swal.fire({
                title: 'Error!',
                text: 'Please set a valid quantity!',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }



    }

    handleSubmit(frontSide, backSide, leftSide, rightSide) {
        const {currentProductQuote} = this.state;

        this.setState({loading: true});
        let FRONT_uploadedFile, FRONT_newUploadedFileName, BACK_uploadedFile, BACK_newUploadedFileName,
            LEFT_uploadedFile, LEFT_newUploadedFileName, RIGHT_uploadedFile, RIGHT_newUploadedFileName;
        if (frontSide) {
            FRONT_uploadedFile = this.frontSideInput.current.files[0];
            FRONT_newUploadedFileName = this.generateImageNewFileName(10) + '--' + FRONT_uploadedFile.name;
            console.log(`%cfrontSide image is Exist`, 'font-size:12px; background-color: #00ff00; color:#111111; padding: 2px 6px; border-radius:4px;');
        }


        if (backSide) {
            BACK_uploadedFile = this.backSideInput.current.files[0];
            BACK_newUploadedFileName = this.generateImageNewFileName(10) + '--' + BACK_uploadedFile.name;
            console.log(`%cbackSide image is Exist`, 'font-size:12px; background-color: #00ff00; color:#111111; padding: 2px 6px; border-radius:4px;');
        }


        if (leftSide) {
            LEFT_uploadedFile = this.leftSideInput.current.files[0];
            LEFT_newUploadedFileName = this.generateImageNewFileName(10) + '--' + LEFT_uploadedFile.name;
            console.log(`%cleftSide image is Exist`, 'font-size:12px; background-color: #00ff00; color:#111111; padding: 2px 6px; border-radius:4px;');
        }


        if (rightSide) {
            RIGHT_uploadedFile = this.rightSideInput.current.files[0];
            RIGHT_newUploadedFileName = this.generateImageNewFileName(10) + '--' + RIGHT_uploadedFile.name;
            console.log(`%crightSide image is Exist`, 'font-size:12px; background-color: #00ff00; color:#111111; padding: 2px 6px; border-radius:4px;');
        }
        let self = this;

        Promise.all([
            frontSide && this.uploadS3Bucket(FRONT_uploadedFile, FRONT_newUploadedFileName, 'front'),
            backSide && this.uploadS3Bucket(BACK_uploadedFile, BACK_newUploadedFileName, 'back'),
            leftSide && this.uploadS3Bucket(LEFT_uploadedFile, LEFT_newUploadedFileName, 'left'),
            rightSide && this.uploadS3Bucket(RIGHT_uploadedFile, RIGHT_newUploadedFileName, 'right')
        ]).then((responses) => {
            let frontResponse = responses[0]
            let backResponse = responses[1]
            let leftResponse = responses[2]
            let rightResponse = responses[3]

            if (frontResponse) {
                this.setState({imageFrontSide: frontResponse.location})
            }
            if (backResponse) {
                this.setState({imageBackSide: backResponse.location})
            }
            if (leftResponse) {
                this.setState({imageLeftSide: leftResponse.location})
            }
            if (rightResponse) {
                this.setState({imageRightSide: rightResponse.location})
            }


            let quoteObject = [...this.props.quote];

            let propsQuote = [...this.props.quote];
            for (let i = 0; i < propsQuote.length; i++) {
                if (propsQuote[i].id === this.props.productId) {
                    // console.log(currentProductQuote[0].photos)
                    if (currentProductQuote[0].photos.frontSideImage !== null && !frontResponse) {
                        quoteObject[i].photos.frontSideImage = currentProductQuote[0].photos.frontSideImage;
                    }else if (currentProductQuote[0].photos.frontSideImage === false && !frontResponse) {
                        quoteObject[i].photos.frontSideImage = null;
                    }else if (currentProductQuote[0].photos.frontSideImage === false && frontResponse) {
                        quoteObject[i].photos.frontSideImage = frontResponse.location;
                    }

                    if (currentProductQuote[0].photos.backSideImage !== null && !backResponse) {
                        quoteObject[i].photos.backSideImage = currentProductQuote[0].photos.backSideImage;
                    }else if (currentProductQuote[0].photos.backSideImage === null && !backResponse) {
                        quoteObject[i].photos.backSideImage = null;
                    }else if (currentProductQuote[0].photos.backSideImage === null && backResponse) {
                        quoteObject[i].photos.backSideImage = backResponse.location;
                    }



                    if (currentProductQuote[0].photos.leftSideImage !== null && !leftResponse) {
                        quoteObject[i].photos.leftSideImage = currentProductQuote[0].photos.leftSideImage;
                    }else if (currentProductQuote[0].photos.leftSideImage === null && !leftResponse) {
                        quoteObject[i].photos.leftSideImage = null;
                    }else if (currentProductQuote[0].photos.leftSideImage === null && leftResponse) {
                        quoteObject[i].photos.leftSideImage = leftResponse.location;
                    }

                    if (currentProductQuote[0].photos.rightSideImage !== null && !rightResponse) {
                        quoteObject[i].photos.rightSideImage = currentProductQuote[0].photos.rightSideImage;
                    }else if (currentProductQuote[0].photos.rightSideImage === null && !rightResponse) {
                        quoteObject[i].photos.rightSideImage = null;
                    }else if (currentProductQuote[0].photos.rightSideImage === null && rightResponse) {
                        quoteObject[i].photos.rightSideImage = rightResponse.location;
                    }

                    // quoteObject[i].photos.backSideImage = backResponse ? backResponse.location : null;
                    // quoteObject[i].photos.leftSideImage = leftResponse ? leftResponse.location : null;
                    // quoteObject[i].photos.rightSideImage = rightResponse ? rightResponse.location : null;
                    quoteObject[i].note = this.state.quoteNote;
                    if (this.state.quantity!=='') {
                        quoteObject[i].quantity = this.state.quantity;
                        this.setState({loading: false});
                        this.props.appendQuote(quoteObject);

                        Swal.fire({
                            title: 'Success!',
                            text: 'Note and photos are successfully updated!',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        }).then(value => {
                            this.props.changeInitialProductQuantity(this.state.quantity,this.props.productId)
                            if (this.props.fromCart && this.props.quoteBasket===0) {
                                this.props.onCloseCartModal()
                            }
                            value.isConfirmed && self.props.modalClose(true);

                        })

                        break;
                    }else {
                        Swal.fire({
                            title: 'Error!',
                            text: 'Please set a valid quantity!',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }

                }
            }
        })

    }


    async uploadS3Bucket(uploadedFile, newUploadedFileName, side) {

        const ReactS3Client = new S3(S3BucketConfig);
        return ReactS3Client.uploadFile(uploadedFile, newUploadedFileName)

    }

    render() {
        const {loading, currentProductQuote} = this.state;
        let previousNote = ''
        let previousPhotos = []
        let previousQuantity = 1;
        if (currentProductQuote.length>0) {
            previousNote = currentProductQuote[0].note;
            previousPhotos = currentProductQuote[0].photos;
            previousQuantity = Number(currentProductQuote[0].quantity);
            // console.log(currentProductQuote[0].quantity);
        }

        return (
            <>
                {loading && <Loading/>}
                <div className="container add-details-modal">
                    <h2>Update Details about the toy</h2>
                    <div className="card">
                        <div className="card-body">
                            {/*<h5 className="card-title"></h5>*/}
                            <div className="row mt-2 mb-2 ">
                                <div className="col-lg-10 col-md-10">
                                    <div className="form-floating">
                                        <textarea className="form-control" placeholder="Leave a note here" id="quote-note" defaultValue={previousNote}
                                                  onChange={(e) => this.handleUpdateQuoteNote(e.target.value)}/>
                                        <label htmlFor="quote-note">Note</label>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2">
                                    <div className="form-floating ">
                                        <input type={'number'}  className="form-control" id="quantity" defaultValue={previousQuantity} maxLength={3}
                                                onChange={(e) => this.handleUpdateQuantity(e.target.value)}/>
                                        <label htmlFor="quantity">Quantity</label>
                                    </div>
                                </div>
                            </div>


                            <div className="row mt-2 mb-2 imageUploadSection">
                                <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                                    <h3>Change Front Side Image</h3>
                                    <div className="input-group">
                                        <input type="file" className="form-control" id={'frontSideInput'}
                                               multiple={false}
                                               ref={this.frontSideInput}
                                               aria-describedby="frontSideInput" aria-label="Upload"/>
                                    </div>


                                    <h3 className={'mt-2'}>Change Back Image</h3>
                                    <div className="input-group">
                                        <input type="file" className="form-control" id={'backSideInput'}
                                               multiple={false}
                                               ref={this.backSideInput}
                                               aria-describedby="backSideInput" aria-label="Upload"/>

                                    </div>

                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                                    <h3>Change Left Side Image</h3>
                                    <div className="input-group">
                                        <input type="file" className="form-control" id={'leftSideInput'}
                                               multiple={false}
                                               ref={this.leftSideInput}
                                               aria-describedby="leftSideInput" aria-label="Upload"/>
                                    </div>

                                    <h3 className={'mt-2'}>Change Right Side Image</h3>
                                    <div className="input-group">
                                        <input type="file" className="form-control" id={'rightSideInput'}
                                               multiple={false}
                                               ref={this.rightSideInput}
                                               aria-describedby="rightSideInput" aria-label="Upload"/>
                                    </div>

                                </div>

                            </div>
                            <div className="row mt-2">

                                <div className="col-lg-6 col-md-6">
                                    <div className="imagePreviews">
                                        {previousPhotos.frontSideImage && <img src={previousPhotos.frontSideImage} alt="imageFrontSide"/>}
                                        {previousPhotos.backSideImage && <img src={previousPhotos.backSideImage} alt="imageBackSide"/>}
                                        {previousPhotos.leftSideImage && <img src={previousPhotos.leftSideImage} alt="imageLeftSide"/>}
                                        {previousPhotos.rightSideImage && <img src={previousPhotos.rightSideImage} alt="imageRightSide"/>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 text-end">
                                    <button className={'btn btn-dark'}
                                            onClick={() => this.submitNoteAndPhotos()}>Upload & Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );

    }
}

export default UpdateNoteAndPhoto;
