import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import Layout from "./ui/Layout";
import {
    BrowserRouter as Router,
} from "react-router-dom";
import pack from '../package.json';
import $ from 'jquery';

console.log(`%cSell Your Toys Now! [App by Optimum7] v${pack.version} [${process.env.NODE_ENV}]`, 'background-color:#048C55; color:#ffffff; padding: 4px 8px;border-radius:6px;')
ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Layout env={process.env.NODE_ENV} />
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

$.fn.isOnScreen = function () {
    var win = $(window);
    var viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();
    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();
    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
};

$(window).scroll(function () {
    if ($('#product-list-table thead').isOnScreen()) {
        $(".scrollTop").removeClass('show')
    } else {
        $(".scrollTop").addClass('show')
    }

    if (window.scrollY<90) {
        $('body').removeClass('fixed')
        $("header").removeClass('fixed')
    } else {
        $('body').addClass('fixed')
        $("header").addClass('fixed')
    }
});
