import React, {Component} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import Search from "../pages/Search";
import Home from "../pages/Home";
import Toys from "../pages/Toys";
import SearchAll from "../pages/SearchAll";
import TagManager from 'react-gtm-module'


const tagManagerArgs = {
    gtmId: 'GTM-K5QT8VJ'
}

class Main extends Component {
    componentDidMount() {
        TagManager.initialize(tagManagerArgs)
    }

    render() {

        return (
            <>
                <main className={'container-fluid no-padding'}>
                    <Switch>
                        <Route path="/search/:slugName/:searchKey" exact={true}>
                            <Search  env={this.props.env}   quote={this.props.quote} appendQuote={(quote) => this.props.appendQuote(quote)}
                                    quoteBasket={this.props.quoteBasket}
                                    changeQuoteBasketAmount={(value) => this.props.changeQuoteBasketAmount(value)}/>
                        </Route>
                        <Route path="/search-all/:searchKey" exact={true}>
                            <SearchAll env={this.props.env}   quote={this.props.quote} appendQuote={(quote) => this.props.appendQuote(quote)}
                                       quoteBasket={this.props.quoteBasket}
                                       changeQuoteBasketAmount={(value) => this.props.changeQuoteBasketAmount(value)}/>
                        </Route>
                        <Route path="/lines/:slugName/toys" exact={true}>
                            <Toys  env={this.props.env}   quote={this.props.quote} appendQuote={(quote) => this.props.appendQuote(quote)}
                                  quoteBasket={this.props.quoteBasket}
                                  changeQuoteBasketAmount={(value) => this.props.changeQuoteBasketAmount(value)}/>
                        </Route>

                        <Route path="/lines/:slugName/toys/:optionName" exact={true}>
                            <Toys  env={this.props.env}   quote={this.props.quote} appendQuote={(quote) => this.props.appendQuote(quote)}
                                  quoteBasket={this.props.quoteBasket}
                                  changeQuoteBasketAmount={(value) => this.props.changeQuoteBasketAmount(value)}/>
                        </Route>

                        <Route path="/">
                            <Home  env={this.props.env}   quote={this.props.quote} appendQuote={(quote) => this.props.appendQuote(quote)}
                                  quoteBasket={this.props.quoteBasket}
                                  changeQuoteBasketAmount={(value) => this.props.changeQuoteBasketAmount(value)}/>
                        </Route>

                    </Switch>
                </main>

            </>
        );
    }
}

export default withRouter(Main);
