import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import '../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.css'
import {Modal} from 'react-responsive-modal';
import SubmitQuoteModal from "../components/common/SubmitQuoteModal";
import Swal from "sweetalert2";
import UpdateNoteAndPhoto from "../components/common/UpdateNoteAndPhoto";
// import Cookies from 'universal-cookie';
class Layout extends Component {
    constructor(props) {
        super(props);
        this.state={
            quoteBasket:0,
            quote: [],
            isModalOpen:false,
            isUpdateModalOpen: false,
            isCustomToyModalOpen: false,
            updateModalProductId: null,
            modalProductId: null
        }
        // this.cookies = new Cookies();
    }
    changeQuoteBasketAmount(value){
        // console.log('basket',value)
        this.setState({quoteBasket:value})
    }
    appendQuote(quoteObject){
        // console.log('append');
        // this.cookies.set('quote', quoteObject, { path: '/' });
        this.setState({quote:quoteObject})
    }
    updateQuote(quoteObject){
        this.setState({quote:quoteObject})
    }
    openQuoteModal(){
        // console.log(this.cookies.get('quote'));
        if (this.state.quote.length > 0) {
            this.setState({isModalOpen: true})
        }else {
            Swal.fire({
                title: 'Warning!',
                text: 'The Quote Cart is empty.',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }

    }
    resetQuoteItem() {
        this.setState({quote: []});
        let updateDetailsButtons = document.querySelectorAll('.updateDetails');
        let updateDetailsButtonsLength = updateDetailsButtons.length;
        let addDetailsButtons = document.querySelectorAll('.addDetails');

        let addDetailsButtonsLength = addDetailsButtons.length;
        let inputQuantity = document.querySelectorAll('input[type="number"]');
        let inputQuantityLength = inputQuantity.length;
        for (let i=0;i<updateDetailsButtonsLength; i++) {
            updateDetailsButtons[i].classList.remove('show');
        }
        for (let k=0;k<addDetailsButtonsLength; k++) {
            addDetailsButtons[k].classList.remove('show');
        }
        for (let j=0;j<inputQuantityLength; j++) {
            inputQuantity[j].value = "0";
        }

    }
    editToyFromCart(id){
        this.setState({updateModalProductId: id})
        this.setState({isUpdateModalOpen: !this.state.isUpdateModalOpen});
    }
    changeInitialProductQuantity(quantity, productId) {
        // console.log(quantity, productId)
        document.getElementById('Quantity-' + productId).value = quantity;
    }
    render() {
        const {quoteBasket,quote,isModalOpen,isUpdateModalOpen,updateModalProductId} = this.state;
        return (
            <>
                <div className="container-fluid no-padding">
                    <Header quoteBasket={quoteBasket} openQuoteModal={()=>this.openQuoteModal()}/>
                    <Main env={this.props.env} changeQuoteBasketAmount={(value)=>this.changeQuoteBasketAmount(value)} quote={quote} appendQuote={(object)=>this.appendQuote(object)} quoteBasket={quoteBasket}/>
                    <Footer/>
                    <Modal open={isModalOpen} onClose={() => {
                        this.setState({isModalOpen: false})
                    }} center>
                        <SubmitQuoteModal quote={quote}
                                          env={this.props.env}
                                          editToyFromCart={(id)=>this.editToyFromCart(id)}
                                          resetQuoteItem={()=>this.resetQuoteItem()}
                                          changeQuoteBasketAmount={(value)=>this.changeQuoteBasketAmount(value)}
                                          updateQuote={(quoteObject)=>this.updateQuote(quoteObject)}
                                          onModalClose={() => {
                                            this.setState({isModalOpen: false})
                                          }}/>
                    </Modal>
                    <Modal open={isUpdateModalOpen} env={this.props.env} onClose={() => {
                        this.setState({isUpdateModalOpen: false})
                    }} center>
                        <UpdateNoteAndPhoto
                            env={this.props.env}
                            quoteBasket={this.state.quoteBasket}
                            quote={quote}
                            modalClose={(value) => {
                                // console.log('modal close', value)
                                this.setState({isUpdateModalOpen: !value})
                            }}
                            onCloseCartModal={()=>this.setState({isModalOpen: false})}
                            fromCart={true}
                            changeInitialProductQuantity={(quantity, productId) => this.changeInitialProductQuantity(quantity, productId)}
                            appendQuote={(quote) => this.appendQuote(quote)}
                            productId={updateModalProductId}/>
                    </Modal>
                </div>
                <button onClick={()=>{window.scrollTo(0,0)}} onTouchStart={()=>{window.scrollTo(0,0)}} className="scrollTop"><i className="las la-arrow-up"/></button>
            </>
        );
    }
}

export default withRouter(Layout);
