import React, {Component} from 'react';


class Footer extends Component {
    render() {
        return (
            <>
                <footer className={'container'}>
                    <ul className={'footer-links'}>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-your-vintage-toys-online-at-sell-your-toys-now/" rel="noreferrer" target="_blank">
                                Sell Vintage Toys
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-80s-toys-to-earn-extra-cash-at-sell-your-toys-now/" rel="noreferrer"  target="_blank">
                                Sell 80’s Toys
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-action-figures-online-at-sell-your-toys-now/" rel="noreferrer"  target="_blank">
                                Sell Action Figures
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-barbies/" rel="noreferrer"  target="_blank">
                                Sell Barbies Online
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-bob-mackie-barbie-dolls/"  rel="noreferrer" target="_blank">
                                Sell Bob Mackie Dolls
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-bratz-dolls/"  rel="noreferrer" target="_blank">
                                Sell Bratz Dolls
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-funko-pop-collectibles-at-sell-your-toys-now/"  rel="noreferrer" target="_blank">
                                Sell Funko Pop
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-gene-dolls-by-ashton-drake-at-sell-your-toys-now/"  rel="noreferrer" target="_blank">
                                Sell Gene Dolls
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-star-wars-toys-online/"  rel="noreferrer" target="_blank">
                                Sell Star Wars toys
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-vintage-dolls-swiftly-profitably-at-sell-your-toys-now/"
                               rel="noreferrer"  target="_blank">
                                Sell Vintage Dolls
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-dc-comics-toys/"  rel="noreferrer" target="_blank">
                                Sell DC Comics toys
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-gi-joes/"  rel="noreferrer" target="_blank">
                                Sell GI Joes online
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-hess-trucks-online/"  rel="noreferrer" target="_blank">
                                Sell Hess trucks
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-your-hot-wheels-online-at-sell-your-toys-now/" rel="noreferrer"  target="_blank">
                                Sell Hot Wheels online
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-your-legos-online-at-sell-your-toys-now/"  rel="noreferrer" target="_blank">
                                Sell Lego set online
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-marvel-toys/" rel="noreferrer"  target="_blank">
                                Sell Marvel Comics toys
                            </a>
                        </li>
                        <li>
                            <a href="https://sellyourtoysnow.com/sell-monster-high-dolls/"  rel="noreferrer" target="_blank">
                                Sell Monster High Dolls
                            </a>
                        </li>
                    </ul>
                </footer>
            </>
        );
    }
}

export default Footer;
