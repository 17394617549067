import React, {PureComponent} from 'react';
import Pagination from "react-js-pagination";
import {withRouter} from "react-router-dom";
import {Modal} from 'react-responsive-modal';
import AddNoteAndPhoto from "./AddNoteAndPhoto";
import Swal from "sweetalert2";
import AddCustomToys from "./AddCustomToys";
import UpdateNoteAndPhoto from "./UpdateNoteAndPhoto";

class ProductList extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            optionNames: [],
            pagination: null,
            isModalOpen: false,
            isUpdateModalOpen: false,
            isCustomToyModalOpen: false,
            updateModalProductId: null,
            quote: [],
            modalProductId: null
        }
    }

    componentDidMount() {
        // console.log('ProductList Did Mount', this.props.products)
        this.setState({products: this.props.products})
        this.setState({optionNames: this.props.optionNames})
        this.setState({pagination: this.props.pagination})
        this.setState({quote: this.props.quote})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('ProductList Did Mount', this.props.products)
        this.setState({products: this.props.products})
        this.setState({optionNames: this.props.optionNames})
        this.setState({pagination: this.props.pagination})

    }

    handlePageChange(value) {
        let searchParams = new URLSearchParams(this.props.history.location.search);
        if (this.props.from === 'Home') {

            let limit = searchParams.get('limit');
            if (limit !== null) {
                this.props.history.push(`/?page=${value}&limit=${limit}`);
            } else {
                this.props.history.push(`/?page=${value}`);
            }
        } else if (this.props.from === 'Search') {
            let limit = searchParams.get('limit');
            if (limit !== null) {
                this.props.history.push(`/search/${this.props.match.params.slugName}/${this.props.match.params.searchKey}?page=${value}&limit=${limit}`);
            } else {
                this.props.history.push(`/search/${this.props.match.params.slugName}/${this.props.match.params.searchKey}?page=${value}`);
            }
        } else if (this.props.from === 'SearchAll') {
            // console.log('searchall')
            let limit = searchParams.get('limit');
            if (limit !== null) {
                this.props.history.push(`/search-all/${this.props.match.params.searchKey}?page=${value}&limit=${limit}`);
            } else {
                this.props.history.push(`/search-all/${this.props.match.params.searchKey}?page=${value}&limit=50`);
            }
        } else {

            let limit = searchParams.get('limit');
            if (limit !== null) {
                this.props.history.push(`/lines/${this.props.match.params.slugName}/toys?page=${value}&limit=${limit}`);
            } else {
                this.props.history.push(`/lines/${this.props.match.params.slugName}/toys?page=${value}`);
            }
        }
        let topSize = document.querySelector('.customProductBtn').offsetTop - 10;
        window.scrollTo(0, topSize);
    }

    openToyRow(product) {
        let quantity = document.getElementsByName('Quantity-' + product.id)[0];
        let quote = [];
        if (quantity.value !== '') {

            quote = [...this.props.quote]

            quote.push({
                product: product,
                id: product.id,
                quantity: quantity.value,
                note: null,
                photos: {frontSideImage: null, backSideImage: null, leftSideImage: null, rightSideImage: null}
            });
            document.getElementById('detail-button-id-' + product.id).classList.add('show');
            // this.setState({quote: quote});

            this.props.appendQuote(quote)
            this.props.changeQuoteBasketAmount(Number(quote.length));
            // this.setState({isModalOpen: !this.state.isModalOpen});


        } else {
            // swal({
            //     title: 'Error!',
            //     text: 'Please set a valid quantity.',
            //     buttons: true
            // })
            Swal.fire({
                title: 'Error!',
                text: 'Please set a valid quantity.',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }

    }

    updateDetails(productId) {
        this.setState({updateModalProductId: productId})
        this.setState({isUpdateModalOpen: !this.state.isUpdateModalOpen});
    }

    addDetails(productId) {
        this.setState({modalProductId: productId})
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    addCustomToy() {
        this.setState({isCustomToyModalOpen: !this.state.isCustomToyModalOpen});
    }

    changeInitialProductQuantity(quantity, productId) {
        // console.log(quantity, productId)
        document.getElementById('Quantity-' + productId).value = quantity;
    }

    render() {
        const {
            optionNames,
            pagination,
            isModalOpen,
            modalProductId,
            isCustomToyModalOpen,
            isUpdateModalOpen,
            updateModalProductId
        } = this.state;
        // console.log('quote', quote);

        return (
            <>

                <div className={'container'}>
                    <div className="addCustomToyContainer">
                        <button className={'btn btn-warning btn-sm customProductBtn'}
                                onClick={() => this.addCustomToy()}><i className="las la-pen"/> Add Custom Toy
                        </button>
                    </div>

                    <div className="table-responsive">

                        <table id={'product-list-table'} className="table table-hover table-sm">
                            <thead className="table-light">
                            <tr>
                                <th width={'240px'}>Name</th>
                                <th width={'100px'}>UPC</th>
                                {optionNames.length > 0 ?
                                    optionNames.map((option, key) => {
                                        if (option === 'Year') {
                                            return (<th key={key} width={'80px'} className={"hide-mobile"}>{option}</th>)
                                        } else if (option === 'Line') {
                                            return (<th key={key} width={'140px'} className={"hide-mobile"}>{option}</th>)
                                        }
                                        return (<th key={key} width={'130px'} className={"hide-mobile"}>{option}</th>)
                                    })
                                    :
                                    null
                                }
                                <th width={'120px'}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                (this.props.products || []).map((product, igKey) => {
                                    return (
                                        <tr key={igKey} data-row-id={'ToyRow-' + product.id}
                                            data-price={product.price}>
                                            <td>{product.product_name}</td>
                                            <td>{product.upc ? <pre>{product.upc}</pre> : ''}</td>
                                            {product.options.map((p, key) => {
                                                return (
                                                    <td key={key} className={"hide-mobile"}>{p.option_value === null ? '' : p.option_value}</td>)
                                            })}
                                            <td>
                                                <div className="input-group quantity-input-group">
                                                    <input type="number" className="form-control form-control-sm"
                                                           placeholder="0"
                                                           aria-label="0"
                                                           aria-describedby="quantity-addon" maxLength={3}
                                                           name={'Quantity-' + product.id}
                                                           id={'Quantity-' + product.id}
                                                           min={0}
                                                           max={999}

                                                    />
                                                    <button onClick={() => this.openToyRow(product)}
                                                            className="btn btn-dark btn-sm" type="button"
                                                            id={'ToyButton-' + product.id}>
                                                        <i className="las la-cart-plus"/>
                                                    </button>
                                                </div>

                                                <button className={'addDetails btn btn-sm btn-primary '}
                                                        id={'detail-button-id-' + product.id}
                                                        onClick={() => this.addDetails(product.id)}><i
                                                    className="las la-edit"/> Add Photos
                                                </button>
                                                <button className={'updateDetails btn btn-sm btn-info '}
                                                        id={'update-detail-button-id-' + product.id}
                                                        onClick={() => this.updateDetails(product.id)}><i
                                                    className="las la-sync"/> Update Photos
                                                </button>

                                            </td>
                                        </tr>)
                                })
                            }

                            </tbody>
                        </table>

                    </div>

                </div>
                {pagination !== null &&
                <nav aria-label="Page navigation example">
                    <Pagination
                        activePage={Number(pagination.current_page)}
                        itemsCountPerPage={Number(pagination.per_page)}
                        totalItemsCount={Number(pagination.product_count)}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass={'page-item'}
                        innerClass={'justify-content-center pagination'}
                        linkClass={'page-link'}
                    />
                </nav>
                }

                <Modal open={isModalOpen} onClose={() => {
                    this.setState({isModalOpen: false})
                }} center>
                    <AddNoteAndPhoto
                        quote={this.props.quote}
                        modalClose={(value) => {
                            // console.log('modal close', value)
                            this.setState({isModalOpen: !value})
                        }}
                        appendQuote={(quote) => this.props.appendQuote(quote)}
                        productId={modalProductId}/>
                </Modal>

                <Modal open={isUpdateModalOpen} onClose={() => {
                    this.setState({isUpdateModalOpen: false})
                }} center>
                    <UpdateNoteAndPhoto
                        quote={this.props.quote}
                        modalClose={(value) => {
                            // console.log('modal close', value)
                            this.setState({isUpdateModalOpen: !value})
                        }}
                        changeInitialProductQuantity={(quantity, productId) => this.changeInitialProductQuantity(quantity, productId)}
                        appendQuote={(quote) => this.props.appendQuote(quote)}
                        productId={updateModalProductId}/>
                </Modal>

                <Modal open={isCustomToyModalOpen} onClose={() => {
                    this.setState({isCustomToyModalOpen: false})
                }} center>

                    <AddCustomToys
                        modalClose={(value) => {
                            this.setState({isCustomToyModalOpen: !value})
                        }}
                        changeQuoteBasketAmount={(value) => this.props.changeQuoteBasketAmount(value)}
                        appendQuote={(quote) => this.props.appendQuote(quote)}
                        quote={this.props.quote}/>
                </Modal>

            </>
        );
    }
}

export default withRouter(ProductList);
