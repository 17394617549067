import React, {PureComponent} from 'react';
// import ENDPOINT from '../common/Endpoints.json';
import axios from "axios";
import Swal from 'sweetalert2';
import noImage from '../../styles/icons/no-image.jpg';

class SubmitQuoteModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            quote: null,
            nameSurname: null,
            email: null,
            phone: null,
            address: null,
            specialNotes: null,
            findUs: null,
            statesPlaceHolder: '',
            state: null,
            city: null
        }
    }

    componentDidMount() {
        this.getUSStates();
        this.setState({quote: this.props.quote})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.quote !== this.props.quote) {
            this.setState({quote: this.props.quote});
            if (this.props.quote.length === 0) {
                this.props.onModalClose();
            }
        }
    }

    handleInformationDetails(fieldName, value) {
        this.setState({[fieldName]: value});
    }

    submitQuoteToAPI() {
        const {nameSurname, email, phone, address, specialNotes, findUs, state, city} = this.state;

        let products = [];
        let custom_products = [];

        this.props.quote.forEach((quote, key) => {
            const imageArray = [
                quote.photos.frontSideImage,
                quote.photos.backSideImage,
                quote.photos.leftSideImage,
                quote.photos.rightSideImage
            ];
            const product = {
                qty: quote.quantity,
                images: imageArray,
                custom_notes: quote.note
            };
            if (quote.type === 'custom_product') {
                custom_products.push({
                    ...product,
                    product_name: quote.product.product_name,
                    upc: quote.product.upc,
                    mpn: quote.product.mpn
                });
            } else {
                products.push({
                    ...product,
                    id: quote.product.id
                });
            }
        });

        if (!nameSurname || !email || !phone || !city) {
            Swal.fire({
                title: 'Warning!',
                text: 'Please fill in the required fields on the form.',
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        }

        if (state === '0' || state === null) {
            Swal.fire({
                title: 'Warning!',
                text: 'Please select a State',
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        }

        const processedData = {
            name: nameSurname,
            email,
            phone,
            // address,
            state_id: state,
            city,
            special_notes: specialNotes,
            how_did_you_find_us: findUs,
            products,
            custom_products
        };

        axios.post(process.env.REACT_APP_SET_QUOTE, processedData)
            .then(response => {
                if (!response.errors && response.data.status) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Your quote request is successfully sent.',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    }).then(value => {
                        if (value.isConfirmed) {
                            this.props.onModalClose();
                            this.props.resetQuoteItem();
                            this.props.changeQuoteBasketAmount(0);
                        }
                    });
                } else {
                    throw Error(response.errors);
                }
            })
            .catch(error => {
                if (error.response.data.errors.email) {
                    Swal.fire({
                        text: error.response.data.errors.email[0],
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            });
    }


    removeToyFromCart(id) {
        let quoteObject = [...this.props.quote];
        (quoteObject || []).map((quote, igKey) => {
            if (quote.id === id) {
                quoteObject.pop(quote)

            }
            return quoteObject
        })
        document.getElementById(`detail-button-id-${id}`).classList.remove('show');
        document.getElementById(`update-detail-button-id-${id}`).classList.remove('show');
        document.getElementById(`Quantity-${id}`).value = '0';
        // console.log(statement)
        console.log(quoteObject)
        this.props.updateQuote(quoteObject);
        this.props.changeQuoteBasketAmount(quoteObject.length);
    }

    getUSStates() {
        axios.get(process.env.REACT_APP_GET_STATES)
            .then(response => {

                let optionsTemplate;
                if (response.data.length > 0) {
                    optionsTemplate = (response.data || []).map((state, key) => {
                        return <option value={state.id} key={key}> {state.name}</option>
                    });
                    this.setState({statesPlaceHolder: optionsTemplate})
                }

            })
    }

    render() {

        // console.log(this.props.quote)
        const {statesPlaceHolder} = this.state;
        if (this.props.quote.length > 0) {
            let quoteSummary = this.props.quote.map((quote, igKey) => {

                return (
                    <tr key={igKey}>
                        <td>
                            <img src={quote.photos.frontSideImage !== null ? quote.photos.frontSideImage : noImage}
                                 className={'img-thumbnail'} alt={quote.product.product_name}/>
                        </td>
                        <td><span>{quote.product.product_name}</span></td>
                        <td><span>x{quote.quantity}</span></td>
                        <td>
                            <button className={"btn-edit"} onClick={() => {
                                this.props.editToyFromCart(quote.id)
                            }}><i className={"las la-pen"}/></button>
                        </td>
                        <td>
                            <button className={"btn-delete remove"} onClick={() => {
                                this.removeToyFromCart(quote.id)
                            }}><i className={"las la-times"}/></button>
                        </td>
                    </tr>
                )
            })
            return (
                <div className={'container submit-quote-modal'}>
                    <h2>Submit a quote</h2>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="quote-summary">
                                <table className={'table table-sm table-striped'}>
                                    <thead>
                                    <tr>
                                        <th width={'80px'}>Image</th>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th width={'10px'}/>
                                        <th width={'10px'}/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {quoteSummary}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <div className="mb-2">
                                        <label htmlFor="nameSurname" className="form-label">Name Surname <span>(*)</span></label>
                                        <input type="text" className="form-control form-control-sm" id="nameSurname" required
                                               onChange={(e) => this.handleInformationDetails(e.target.id, e.target.value)}/>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <div className="mb-2">
                                        <label htmlFor="email" className="form-label">E-mail Address <span>(*)</span></label>
                                        <input type="email" className="form-control form-control-sm" id="email" required
                                               onChange={(e) => this.handleInformationDetails(e.target.id, e.target.value)}/>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <div className="mb-2">
                                        <label htmlFor="phone" className="form-label">Phone <span>(*)</span></label>
                                        <input type="tel" className="form-control form-control-sm" id="phone" required
                                               onChange={(e) => this.handleInformationDetails(e.target.id, e.target.value)}/>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-2">
                                                <label htmlFor="state" className="form-label">State <span>(*)</span></label>
                                                <select className="form-select form-select-sm" name="state" id="state" required
                                                        onChange={(e) => this.handleInformationDetails(e.target.id, e.target.value)}>
                                                    <option value="0">Select a state</option>
                                                    {statesPlaceHolder}
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-2">
                                                <label htmlFor="state" className="form-label">City <span>(*)</span></label>
                                                <input type="text" className={"form-control form-control-sm"}
                                                       required
                                                       id={'city'}
                                                       onChange={(e) => this.handleInformationDetails(e.target.id, e.target.value)}/>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <div className="mb-2">
                                        <label htmlFor="specialNotes" className="form-label">Quote Note </label>
                                        <textarea className="form-control form-control-sm" id="specialNotes"
                                                  onChange={(e) => this.handleInformationDetails(e.target.id, e.target.value)}/>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <div className="mb-2">
                                        <label htmlFor="findUs" className="form-label">How did you find us?</label>
                                        <input type="text" className="form-control form-control-sm" id="findUs"
                                               onChange={(e) => this.handleInformationDetails(e.target.id, e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <button className={'btn btn-success'} onClick={() => {
                                this.submitQuoteToAPI()
                            }}>Submit
                            </button>
                        </div>
                    </div>

                </div>
            )
        } else {

            return (
                <>Loading</>
            );
        }

    }
}

export default SubmitQuoteModal;
